import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import {
  assertAccountIdHasPermissionToPersistChanges,
  PersistChangesParams,
  persistChangesToGame
} from "./helpers/persistChangesToGame";

export const soccerGame__server__persistChanges = (p: PersistChangesParams) => {
  return persistChangesToGame(p);
};

soccerGame__server__persistChanges.auth = async (req: { body: PersistChangesParams }) => {
  await Promise.all([
    validateTokenAndEnsureSelfAccountIdMatches(req as any)
    // TODO: Add security. Need to send along the team id
    // assertAccountIdHasPermissionToPersistChanges({
    //   accountId: req.body.selfAccountId,
    //   teamId: req.body.teamId
    // })
  ]);
};
// i18n certified - complete
