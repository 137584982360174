import { SoccerGame, isPostStartedSoccerGame } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { refreshStatSnapshots } from "./helpers/refreshStatSnapshots";
import { soccerGameEventBundle__client__getEvents } from "../soccerGameEventBundle.api";
import { soccerGame__client__byCalendarId } from "./soccerGame__byCalendarId";
import { validateToken } from "../../internal-utils/server-auth";

export async function soccerGame__server__refreshStats(p: { soccerGameId: string } | { calendarEntryId: string }) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  let soccerGamePromise: Promise<SoccerGame | null>;
  let soccerGameId: string;
  if ("calendarEntryId" in p) {
    const tempSoccerGame = await soccerGame__client__byCalendarId(p);

    if (!tempSoccerGame) {
      throw new Error("Invalid calendarEntryId passed to refreshStats");
    }

    soccerGamePromise = Promise.resolve(tempSoccerGame);
    soccerGameId = tempSoccerGame.id;
  } else {
    soccerGamePromise = h.SoccerGame.getDoc(p.soccerGameId);
    soccerGameId = p.soccerGameId;
  }

  const [game, events] = await Promise.all([soccerGamePromise, soccerGameEventBundle__client__getEvents({ soccerGameId })]);

  if (!game || !events) {
    throw new Error("Unable to find game and/or events for the supplied parameters! " + JSON.stringify(p));
  }

  if (game.gameStage !== "ended") {
    throw new Error("Unable to refresh stats snapshot on a non ended game");
  }

  await refreshStatSnapshots({
    events,
    game
  });
  // SERVER_ONLY_TOGGLE
}

soccerGame__server__refreshStats.auth = async (r: any) => {
  await validateToken(r);
};

// i18n certified - complete
