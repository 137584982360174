import { SoccerGame, SoccerGameId, Votes, AccountId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";

export async function soccerGame__server__setLiveGameModePleaForPhotos(p: { soccerGameId: SoccerGameId }) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  await h.SoccerGame.setPath({
    id: p.soccerGameId,
    pathObj: { liveGameModePleaForPhotosHasBeenSent: true },
    value: { liveGameModePleaForPhotosHasBeenSent: true }
  });
  // SERVER_ONLY_TOGGLE
}

soccerGame__server__setLiveGameModePleaForPhotos.auth = () => {};
// i18n certified - complete
