import { getUniversalHelpers, getServerHelpers } from "../../helpers";
import { soccerGame__client__getGameData } from "./soccerGame__getGameData";
import { CalendarEntry, EndedSoccerGame, Org, PostStartedSoccerGame, SoccerStatSnapshot } from "@ollie-sports/models";
import { createSoccerStatSnapshotBundle, SoccerStatSnapshotBundle } from "../../soccer-logic";
import { validateSelfAccountId, validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import { soccerLogicVersion } from "../../soccer-logic-version.json";
import { getStatBundleAndMaybeRefresh } from "./helpers/getStatBundleAndMaybeRefresh";

type ThenArg<T> = T extends PromiseLike<infer U> ? U : T extends (...args: any[]) => PromiseLike<infer V> ? V : T;

type ReturnData = ThenArg<ReturnType<typeof soccerGame__client__getGameData>> & {
  calendarEntry: CalendarEntry;
  statBundle: SoccerStatSnapshotBundle;
};

//TODO: Long term make Bifrost honor the "Content-Encoding" header rather than doing these janky parameters and return values
export async function soccerGame__server__getStatsViewingGameData(p: {
  calendarEntryId: string;
  selfAccountId: string | "super-admin-magic-string";
  locale: string;
}): Promise<ReturnData> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();

  const [calendarEntry, soccerGames] = await Promise.all([
    h.CalendarEntry.getDoc(p.calendarEntryId),
    h.SoccerGame.query({ where: [{ calendarEntryId: ["==", p.calendarEntryId] }] })
  ]);

  const soccerGame = soccerGames?.docs[0];

  if (!calendarEntry || !soccerGame) {
    throw new Error("Invalid parameters passed to refreshStatSnapshots!");
  }

  if (soccerGame.calendarEntryId !== calendarEntry.id) {
    throw new Error("Invalid soccerGameId passed to refreshStatSnapshots!");
  }

  if (soccerGame.gameStage === "pre-setup" || soccerGame.gameStage === "setup") {
    throw new Error("Unable to view stats on a game that has now yet started.");
  }

  const [gameData, snapshots] = await Promise.all([
    soccerGame__client__getGameData({ calendarEntry }),
    h.SoccerStatSnapshot.query({
      where: [{ calendarEntryId: ["==", p.calendarEntryId] }, { soccerLogicVersion: ["==", soccerLogicVersion] }]
    })
  ]);

  if (
    !gameData.team.accounts[p.selfAccountId] &&
    //We need this magic admin string for a utility script. Should be secure since the token gets validated in any outward facing endpoint
    p.selfAccountId !== "super-admin-magic-string"
  ) {
    let org: Org | null = null;
    if (gameData.team.orgId) {
      org = await h.Org.getDoc(gameData.team.orgId);
    }
    if (!(org && !!org.accounts[p.selfAccountId])) {
      throw new Error("Unable to view stats since the user is not a member of the team");
    }
  }
  if (soccerGame.gameStage === "ended") {
    //If ended, stat snapshots should already be computed
    return {
      ...gameData,
      calendarEntry,
      statBundle: await getStatBundleAndMaybeRefresh({
        events: gameData.events,
        game: soccerGame,
        snapshots: snapshots.docs
      })
    };
  } else {
    return {
      ...gameData,
      calendarEntry,
      statBundle: createSoccerStatSnapshotBundle({
        events: gameData.events,
        game: gameData.game as PostStartedSoccerGame,
        locale: p.locale
      })
    };
  }
  // SERVER_ONLY_TOGGLE
}

soccerGame__server__getStatsViewingGameData.auth = async (req: any) => {
  await validateTokenAndEnsureSelfAccountIdMatches(req);
};

// i18n certified - complete
