import { EndedSoccerGame, PostStartedSoccerGame, SoccerGameEvent, SoccerStatSnapshot } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../../helpers";
import { SoccerStatSnapshotBundle } from "../../../soccer-logic";
import { soccerLogicVersion } from "../../../soccer-logic-version.json";
import { refreshStatSnapshots } from "./refreshStatSnapshots";

export async function getStatBundleAndMaybeRefresh(p: {
  events: SoccerGameEvent[];
  game: EndedSoccerGame;
  snapshots: SoccerStatSnapshot[];
}): Promise<SoccerStatSnapshotBundle> {
  const { olliePipe } = getUniversalHelpers();

  const namedSnapshots = {
    all: p.snapshots.find(a => a.snapshotType === "all") as SoccerStatSnapshot,
    firstHalf: p.snapshots.find(a => a.snapshotType === "first-half") as SoccerStatSnapshot,
    secondHalfPlus: p.snapshots.find(a => a.snapshotType === "second-half-plus") as SoccerStatSnapshot
  };

  let statBundle: SoccerStatSnapshotBundle = {
    type: "full",
    ...namedSnapshots
  };

  const mustRefreshSnapshots = !Object.values(namedSnapshots).every(a => a);

  if (mustRefreshSnapshots) {
    //Refresh the stats if the snapshots don't already exist.
    olliePipe.emitEvent({
      type: "error-cache-miss-refresh-stats-snapshot",
      payload: {
        soccerLogicVersion,
        soccerGameId: p.game.id
      }
    });
    statBundle = await refreshStatSnapshots({ events: p.events, game: p.game });
  }

  return statBundle;
}

// i18n certified - complete
