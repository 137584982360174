import { getUniversalHelpers } from "../../../helpers";
import {
  PreSetupSoccerGame,
  SoccerMaxFieldPlayers,
  SoccerGame,
  sandboxTeamId,
  sandboxCalendarEntryId,
  SoccerPositionNumber,
  TEAM_TYPES,
  FeatureToggles,
  SoccerStatModes,
  MVPVotingMode
} from "@ollie-sports/models";
import { SimpleQuery } from "@ollie-sports/firebase-lift";
import { player__client__rawPlayersOnATeam } from "../../player.api";
import { computeStarterPlayerIdsNotValidForFormation } from "../../../soccer-logic";
import { common__client__getGenerator } from "../../common.api";
import _ from "lodash";
import { fetchFeatureToggle } from "../../../utils/feature-toggle";

export async function fetchDefaultGameForTeam(p: {
  calendarEntryId: string;
  teamId: string;
  recorderAccountId: string;
  squad: "a" | "b" | "c" | undefined;
}): Promise<PreSetupSoccerGame> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const team = await h.Team.getDoc(p.teamId);

  let defaultClockMode: "nfhs" | "default" | undefined =
    (team?.country === "USA" || team?.country === "US") && [TEAM_TYPES.highschool, TEAM_TYPES.college].includes(team.teamType)
      ? "nfhs"
      : "default";

  const defaultSoccerGame: PreSetupSoccerGame = {
    id: common__client__getGenerator().SoccerGameId(p.calendarEntryId),
    createdAtMS: Date.now(),
    calendarEntryId: p.calendarEntryId,
    recorderAccountId: p.recorderAccountId,
    gameStage: "pre-setup",
    teamId: p.teamId,
    maxFieldPlayers: SoccerMaxFieldPlayers.n11,
    fieldSide: "left",
    clockMode: defaultClockMode,
    areMVPsDisbaled: p.calendarEntryId === sandboxCalendarEntryId ? false : !team?.enabledFeatures?.mvps,
    votingMode: team?.votingMode || MVPVotingMode.none,
    areAwardsDisabled: p.calendarEntryId === sandboxCalendarEntryId ? false : !team?.enabledFeatures?.awards
  };

  if (p.squad) {
    defaultSoccerGame.squad = p.squad;
  }

  const prevGameQuery: SimpleQuery<SoccerGame> = {
    where: [{ teamId: ["==", p.teamId] }],
    orderBy: [{ pathObj: { officialEndOfGameMS: true }, dir: "desc" }],
    limit: 1
  };

  if (p.squad && prevGameQuery.where) {
    prevGameQuery.where.push({ squad: ["==", p.squad] });
  }

  const prevAdvancedGameQuery = _.cloneDeep(prevGameQuery);
  if (prevAdvancedGameQuery.where) {
    prevAdvancedGameQuery.where.push({ statMode: ["==", SoccerStatModes.individual] });
  }

  const [prevGameResp, prevAdvancedGameResp] = await Promise.all([
    h.SoccerGame.query(prevGameQuery),
    h.SoccerGame.query(prevAdvancedGameQuery)
  ]);

  const prevGame = prevGameResp.docs.slice().pop();
  const prevAdvancedGame = prevAdvancedGameResp.docs.slice().pop();

  const game = prevGame
    ? {
        ...defaultSoccerGame,
        ...{
          maxFieldPlayers: prevAdvancedGame?.maxFieldPlayers,
          halfLengthMinutes: prevGame.halfLengthMinutes,
          starterIdToPosition: _.invert(_.invert(prevAdvancedGame?.starterIdToPosition || {})) as any, //Remove duplicate positions
          startingFormation: prevAdvancedGame?.startingFormation,
          statMode: prevGame.statMode,
          clockMode: prevGame.clockMode ?? defaultSoccerGame.clockMode
        }
      }
    : defaultSoccerGame;

  //Remove players from roster/starterIdToPosition that used to be on the team/squad when the previous game was recorded but no longer are.
  if (p.teamId !== sandboxTeamId) {
    const currentlyValidPlayerIds = (await player__client__rawPlayersOnATeam({ teamId: p.teamId, squad: p.squad })).reduce(
      (acc, pl) => {
        acc[pl.id] = true;
        return acc;
      },
      {} as Record<string, true>
    );

    Object.keys(game.roster || {}).forEach(pid => {
      if (!currentlyValidPlayerIds[pid]) {
        delete (game.roster || {})[pid];
      }
    });

    Object.keys(game.starterIdToPosition || {}).forEach(pid => {
      if (!currentlyValidPlayerIds[pid]) {
        delete (game.starterIdToPosition || {})[pid];
      }
    });
  }

  //Shouldn't matter, but just in case, remove starters that are not valid for the given formation
  if (game.starterIdToPosition && game.startingFormation) {
    const invalidStarterIds: string[] = computeStarterPlayerIdsNotValidForFormation({
      starterIdToPosition: game.starterIdToPosition || {},
      formation: game.startingFormation
    });

    invalidStarterIds.forEach(playerId => {
      delete (game.starterIdToPosition || {})[playerId];
    });
  }

  if (game.starterIdToPosition && !game.startingFormation) {
    delete game.starterIdToPosition; //Shouldn't ever happen, but just in case...
  }

  if (!(await fetchFeatureToggle(FeatureToggles.enableStopClockMode))) {
    delete game.clockMode;
  }

  return _.omitBy(game, v => v === undefined) as PreSetupSoccerGame;
}

// i18n certified - complete
