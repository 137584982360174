import { AllCollectionNames, CalendarEntry, SoccerGameRTDBInfo } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { fetchDefaultGameForTeam } from "./helpers/fetchDefaultGameForTeam";
import { RTBDRefs } from "../../constants";

export async function soccerGame__client__add(p: { calendarEntry: CalendarEntry; recorderAccountId: string }): Promise<string> {
  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();

  const game = await fetchDefaultGameForTeam({
    calendarEntryId: p.calendarEntry.id,
    recorderAccountId: p.recorderAccountId,
    teamId: p.calendarEntry.teamId,
    squad: p.calendarEntry.squad
  });

  const resp = await h._RawFirestore.runTransaction(async transaction => {
    const gameRef = h._RawFirestore.collection(AllCollectionNames.soccerGame).doc(game.id);
    const gameSnap = await transaction.get(gameRef);

    if (gameSnap.exists) {
      return "alreadyExists";
    } else {
      await transaction.set(gameRef, game);
      return "created";
    }
  });

  if (resp === "alreadyExists") {
    return game.id;
  }

  await RTBDRefs.getSoccerGameInfoRef({ soccerGameId: game.id })
    .set({
      expireMS: Date.now() + 1000 * 60 * 60 * 24 * 2, //Expire two days from now
      recorderHeartbeatMS: Date.now()
    })
    .catch(e => {
      olliePipe.emitEvent({ type: "error-failed-soccer-game-rtdb-initialization", payload: e });
    });

  return game.id;
}

// i18n certified - complete
