import {
  SoccerGame,
  SoccerGameId,
  Votes,
  AccountId,
  MvpTypes,
  PlayerId,
  SoccerStatModes,
  StartedSoccerGame,
  EndedSoccerGame
} from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { notification__server__triggerForVotingClosed } from "../notification";
import { computeSoccerGameAwards, createSoccerStatSnapshotBundle } from "../../soccer-logic";
import { Request } from "express";
import { soccerGameEventBundle__client__getEvents } from "../soccerGameEventBundle.api";
import moment from "moment-timezone";
import _ from "lodash";
import { ObjectKeys } from "../../utils/object-keys";
import { validateToken } from "../../internal-utils/server-auth";

export async function soccerGame__server__endVoting(p: {
  data: {
    soccerGameId: SoccerGameId;
    disableNotification?: true;
  };
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const soccerGame = await h.SoccerGame.getDoc(p.data.soccerGameId);

  // If we can't find the soccer game, the game hasn't ended, or there are already winners computed, just do nothing
  if (!soccerGame || soccerGame.gameStage !== "ended" || !!soccerGame.mvpWinners) {
    return;
  }

  const events = await soccerGameEventBundle__client__getEvents({ soccerGameId: soccerGame.id });

  const snapshot = createSoccerStatSnapshotBundle({
    events: events ?? [],
    game: soccerGame,
    locale: "en-us"
  });

  const { mvps } = computeSoccerGameAwards({
    game: soccerGame,
    snapshot: snapshot.all,
    locale: "en-us"
  });

  let numVotesMVP: Record<PlayerId, number> = {};
  let numVotesOffense: Record<PlayerId, number> = {};
  let numVotesDefense: Record<PlayerId, number> = {};
  if (soccerGame.votes) {
    if (ObjectKeys(soccerGame.votes).length > 0) {
      // Count the votes
      ObjectKeys(soccerGame.votes).forEach(accountId => {
        if (soccerGame.votes) {
          const votes = soccerGame.votes[accountId];
          if (votes) {
            const mvpPlayerId = votes["mvp"];
            if (!numVotesMVP[mvpPlayerId]) {
              numVotesMVP[mvpPlayerId] = 1;
            } else {
              numVotesMVP[mvpPlayerId] = numVotesMVP[mvpPlayerId] + 1;
            }

            const offensePlayerId = votes["bestOffense"];
            if (!numVotesOffense[offensePlayerId]) {
              numVotesOffense[offensePlayerId] = 1;
            } else {
              numVotesOffense[offensePlayerId] = numVotesOffense[offensePlayerId] + 1;
            }

            const defensePlayerId = votes["bestDefense"];
            if (!numVotesDefense[defensePlayerId]) {
              numVotesDefense[defensePlayerId] = 1;
            } else {
              numVotesDefense[defensePlayerId] = numVotesDefense[defensePlayerId] + 1;
            }
          }
        }
      });
    }
  }

  // Determine the winners
  let mvpWinnerPlayerId = mvps.mvp.slice().shift()?.playerId;
  let mvpWinnerVotes: number = 0;
  ObjectKeys(numVotesMVP).forEach(a => {
    if (numVotesMVP[a] > mvpWinnerVotes) {
      mvpWinnerPlayerId = a;
      mvpWinnerVotes = numVotesMVP[a];
    } else if (numVotesMVP[a] === mvpWinnerVotes) {
      // If team mode, break ties with a random choice for now...
      if (soccerGame.statMode === SoccerStatModes.team) {
        const randomNumberBetween1And2 = Math.floor(Math.random() * 2) + 1;
        if (randomNumberBetween1And2 === 1) {
          mvpWinnerPlayerId = a;
          mvpWinnerVotes = numVotesMVP[a];
        }
      }

      // If not team mode, break ties using the composite score
      else {
        const newPlayerCompositeValue = mvps.mvp.find(b => b.playerId === a)?.value;
        const oldPlayerCompositeValue = mvps.mvp.find(b => b.playerId === mvpWinnerPlayerId)?.value;
        if (newPlayerCompositeValue && oldPlayerCompositeValue && newPlayerCompositeValue > oldPlayerCompositeValue) {
          mvpWinnerPlayerId = a;
          mvpWinnerVotes = numVotesMVP[a];
        }
      }
    }
  });

  let offenseWinnerPlayerId = mvps.bestOffense.slice().shift()?.playerId;
  let offenseWinnerVotes: number = 0;
  ObjectKeys(numVotesOffense).forEach(a => {
    if (numVotesOffense[a] > offenseWinnerVotes) {
      offenseWinnerPlayerId = a;
      offenseWinnerVotes = numVotesOffense[a];
    } else if (numVotesOffense[a] === offenseWinnerVotes) {
      // If team mode, break ties with a random choice for now...
      if (soccerGame.statMode === SoccerStatModes.team) {
        const randomNumberBetween1And2 = Math.floor(Math.random() * 2) + 1;
        if (randomNumberBetween1And2 === 1) {
          offenseWinnerPlayerId = a;
          offenseWinnerVotes = numVotesOffense[a];
        }
      }
      // Break ties using the composite score
      else {
        const newPlayerCompositeValue = mvps.bestOffense.find(b => b.playerId === a)?.value;
        const oldPlayerCompositeValue = mvps.bestOffense.find(b => b.playerId === offenseWinnerPlayerId)?.value;
        if (newPlayerCompositeValue && oldPlayerCompositeValue && newPlayerCompositeValue > oldPlayerCompositeValue) {
          offenseWinnerPlayerId = a;
          offenseWinnerVotes = numVotesOffense[a];
        }
      }
    }
  });

  let defenseWinnerPlayerId = mvps.bestDefense.slice().shift()?.playerId;
  let defenseWinnerVotes: number = 0;
  ObjectKeys(numVotesDefense).forEach(a => {
    if (numVotesDefense[a] > defenseWinnerVotes) {
      defenseWinnerPlayerId = a;
      defenseWinnerVotes = numVotesDefense[a];
    } else if (numVotesDefense[a] === defenseWinnerVotes) {
      // If team mode, break ties with a random choice for now...
      if (soccerGame.statMode === SoccerStatModes.team) {
        const randomNumberBetween1And2 = Math.floor(Math.random() * 2) + 1;
        if (randomNumberBetween1And2 === 1) {
          defenseWinnerPlayerId = a;
          defenseWinnerVotes = numVotesDefense[a];
        }
      }
      // Break ties using the composite score
      else {
        const newPlayerCompositeValue = mvps.bestDefense.find(b => b.playerId === a)?.value;
        const oldPlayerCompositeValue = mvps.bestDefense.find(b => b.playerId === defenseWinnerPlayerId)?.value;
        if (newPlayerCompositeValue && oldPlayerCompositeValue && newPlayerCompositeValue > oldPlayerCompositeValue) {
          defenseWinnerPlayerId = a;
          defenseWinnerVotes = numVotesDefense[a];
        }
      }
    }
  });

  //Make sure the another simultaneous invocation of the function hasn't already set the winners.
  const raceConditionCheck = await h.SoccerGame.getDoc(p.data.soccerGameId);
  if (raceConditionCheck && "mvpWinners" in raceConditionCheck) {
    return;
  }

  let mvpWinners: EndedSoccerGame["mvpWinners"] = {
    bestDefense: defenseWinnerPlayerId,
    bestOffense: offenseWinnerPlayerId,
    mvp: mvpWinnerPlayerId
  };

  mvpWinners = _.omitBy(mvpWinners, _.isUndefined);

  await h.SoccerGame.updateShallow({
    id: p.data.soccerGameId,
    doc: {
      mvpWinners
    }
  });

  if (p.data.disableNotification || !Object.keys(mvpWinners).length) {
    //Don't notify... Only happens if disabled or on beginner mode with no votes cast
  } else {
    await notification__server__triggerForVotingClosed({ soccerGame, numVotesDefense, numVotesOffense, numVotesMVP });
  }
  // SERVER_ONLY_TOGGLE
}

soccerGame__server__endVoting.auth = async (req: Request) => {
  if (req.get("x-ph-signature")) {
    //TODO: Verify posthook signature...
  } else {
    await validateToken(req);
  }
};

// i18n certified - complete
