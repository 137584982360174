import { SoccerGame, SoccerGameId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function soccerGame__client__clearEnqueuedSub(p: { gameId: SoccerGameId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  await h.SoccerGame.update({
    id: p.gameId,
    doc: {
      queuedPlayerIdToNewPosition: h._MagicDeleteValue,
      queuedPlayerIdToOldPosition: h._MagicDeleteValue,
      queuedSubAccountId: h._MagicDeleteValue
    }
  });
}
// i18n certified - complete
