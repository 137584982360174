import { SoccerGame, SoccerGameId, Votes, AccountId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";

export async function soccerGame__server__saveVote(p: { gameId: SoccerGameId; votes: Votes; accountId: AccountId }) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  await h.SoccerGame.update({
    id: p.gameId,
    doc: {
      votes: {
        [p.accountId]: p.votes
      }
    }
  });
  // SERVER_ONLY_TOGGLE
}

soccerGame__server__saveVote.auth = () => {};
// i18n certified - complete
