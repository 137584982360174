import { getUniversalHelpers } from "../../helpers";
import { SoccerGameEvent } from "@ollie-sports/models";

export function soccerGame__client__queryEvents(p: { soccerGameId: string }): Promise<SoccerGameEvent[]> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return h.SoccerGameEvent.query({
    where: [{ soccerGameId: ["==", p.soccerGameId] }],
    orderBy: [{ dir: "asc", pathObj: { createdAtMS: true } }],
    limit: 10000
  }).then(resp => resp.docs);
}

// i18n certified - complete
