import { SoccerGame, SoccerGameId, Votes, AccountId, QueuedSubProps } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function soccerGame__client__enqueueSub(p: { gameId: SoccerGameId; subs: QueuedSubProps }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  await h.SoccerGame.updateShallow({
    id: p.gameId,
    doc: {
      queuedPlayerIdToNewPosition: p.subs.queuedPlayerIdToNewPosition,
      queuedPlayerIdToOldPosition: p.subs.queuedPlayerIdToOldPosition,
      queuedSubAccountId: p.subs.queuedSubAccountId
    }
  });
}

// i18n certified - complete
