import { BatchTask } from "@ollie-sports/firebase";
import { EndedSoccerGame, SoccerGameEvent, SoccerStatSnapshot } from "@ollie-sports/models";
import { getServerHelpers, getUniversalHelpers } from "../../../helpers";
import { createSoccerStatSnapshotBundle, FullSoccerStatSnapshotBundle } from "../../../soccer-logic";
import { common__client__getGenerator } from "../../common.api";

type BaseProps = {
  game: EndedSoccerGame;
  events: SoccerGameEvent[];
};

export async function refreshStatSnapshots(p: BaseProps): Promise<FullSoccerStatSnapshotBundle>;
export async function refreshStatSnapshots(
  p: BaseProps & { returnBatchTask: true }
): Promise<FullSoccerStatSnapshotBundle & { batchTasks: BatchTask[] }>;
export async function refreshStatSnapshots(
  p: BaseProps & { returnBatchTask?: boolean }
): Promise<FullSoccerStatSnapshotBundle & { batchTasks?: BatchTask[] }> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const bundle = createSoccerStatSnapshotBundle({ ...p, locale: "en-us" });

  if (bundle.type !== "full") {
    throw new Error("Something weird happened. Should never refresh stat snapshots on a non-ended game.");
  }

  const ids = common__client__getGenerator();

  const batchTasks: BatchTask[] = [
    await h.SoccerStatSnapshot.set(
      {
        id: ids.SoccerStatSnapshotId(p.game.id, "all"),
        doc: {
          ...bundle.all,
          id: ids.SoccerStatSnapshotId(p.game.id, "all")
        }
      },
      { returnBatchTask: true }
    ),
    await h.SoccerStatSnapshot.set(
      {
        id: ids.SoccerStatSnapshotId(p.game.id, "first-half"),
        doc: {
          ...bundle.firstHalf,
          id: ids.SoccerStatSnapshotId(p.game.id, "first-half")
        }
      },
      { returnBatchTask: true }
    ),
    await h.SoccerStatSnapshot.set(
      {
        id: ids.SoccerStatSnapshotId(p.game.id, "second-half-plus"),
        doc: {
          ...bundle.secondHalfPlus,
          id: ids.SoccerStatSnapshotId(p.game.id, "second-half-plus")
        }
      },
      { returnBatchTask: true }
    )
  ];

  const snapshots = {
    type: "full" as const,
    all: bundle.all,
    firstHalf: bundle.firstHalf,
    secondHalfPlus: bundle.secondHalfPlus
  };

  if (p.returnBatchTask) {
    return {
      ...snapshots,
      batchTasks
    };
  } else {
    await h._BatchRunner.executeBatch(batchTasks);
    return snapshots;
  }
  // SERVER_ONLY_TOGGLE
}

// i18n certified - complete
