import {
  SoccerGameEvent,
  EndedSoccerGame,
  SoccerGameEventType,
  CALENDAR_ENTRY_TYPES,
  SoccerGameCollectionName,
  SoccerGame,
  PostStartedSoccerGame,
  StartedSoccerGame,
  SoccerStatModes,
  MVPVotingMode
} from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers, getServerHelpers } from "../../helpers";
import { schedulePosthook } from "../../internal-utils/schedulePosthook";
import { soccerGame__server__endVoting } from "./soccerGame__endVotingV2";
import { soccerGame__client__queryEvents } from "./soccerGame__queryEvents";
import { soccerGameEventBundle__client__refresh } from "../soccerGameEventBundle.api";
import { BatchTask } from "@ollie-sports/firebase";
import { validateSelfAccountId } from "../../internal-utils/server-auth";
import { common__decompressString, common__hashObject } from "../common.api";
import { team__server__checkForOnboardingComplete } from "../team/team__checkForOnboardingComplete";
import { notification__server__triggerForNewStats } from "../notification.api";
import { refreshStatSnapshots } from "./helpers/refreshStatSnapshots";
import { computeSoccerGameAwards, computeSoccerGameEndGameDetails, isGoalEvent } from "../../soccer-logic";
import moment from "moment-timezone";
import { getBatchTasksForEndingAGame } from "./helpers/getBatchTasksForEndingAGame";
import { soccerGame__server__sendVotingReminder } from "./soccerGame__sendVotingReminder";
import { soccerGame__server__maybeSendCoachShareReminder } from "./soccerGame__maybeSendCoachShareReminder";
import { timeHasPassedSinceScheduledGameStart } from "../../compute";
import { emitMarketingEvent } from "../../utils";

export async function soccerGame__server__finalizeStatsRecording(p: {
  soccerGameId: string;
  selfAccountId: string;
  md5OfEventsUpToEndEvent: string;
  numEventsUpToEndEvent: number;
  endGameEvent: SoccerGameEvent;
  compressedAllEventsUpToEndEvent?: string;
  locale: string;
}): Promise<{ result: "success" | "hash-mismatch" }> {
  // SERVER_ONLY_TOGGLE
  const { serverConfig } = getServerHelpers();
  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();

  let currentStartedGameRaw = await h.SoccerGame.getDoc(p.soccerGameId);

  if (!currentStartedGameRaw || currentStartedGameRaw.gameStage === "pre-setup") {
    await olliePipe.emitEvent(
      {
        type: "error-game-finalize-not-yet-setup",
        payload: {
          soccerGameId: p.soccerGameId
        }
      },
      { sendImmediate: true }
    );
    throw new Error("Game has not yet been setup");
  }

  if (currentStartedGameRaw?.gameStage === "ended") {
    //If ended, just return early and assume stats are already computed and that this is just a retry misfire.
    return { result: "success" };
  }

  if (!p.endGameEvent || p.endGameEvent.type !== SoccerGameEventType.stopHalf || !p.endGameEvent.endsGame) {
    throw new Error("Final event from compressedFinalEvents is not of correct type to end game");
  }

  if (currentStartedGameRaw.recorderAccountId !== p.selfAccountId) {
    await olliePipe.emitEvent(
      {
        type: "error-game-finalize-no-permission",
        payload: {
          recorder: currentStartedGameRaw.recorderAccountId,
          selfAccountId: p.selfAccountId,
          soccerGameId: p.soccerGameId
        }
      },
      { sendImmediate: true }
    );
    throw new Error("User does not have permission to end game");
  }

  const serverQueriedEvents = await soccerGame__client__queryEvents({ soccerGameId: p.soccerGameId });

  const outOfSyncTasks: BatchTask[] = [];

  let eventsUpToEndEvent: SoccerGameEvent[];
  if (p.compressedAllEventsUpToEndEvent) {
    eventsUpToEndEvent = JSON.parse(common__decompressString({ str: p.compressedAllEventsUpToEndEvent }));

    if (common__hashObject({ obj: eventsUpToEndEvent }) !== p.md5OfEventsUpToEndEvent) {
      await olliePipe.emitEvent(
        {
          type: "error-all-compressed-events-do-not-match-supplied-hash",
          payload: {
            soccerGameId: currentStartedGameRaw.id,
            numEventsOnClientHash: p.numEventsUpToEndEvent,
            numEventsOnServerFetch: serverQueriedEvents.length
          }
        },
        { sendImmediate: true }
      );
    }

    //Eventually, if we determine that this analytic never gets triggered, we can remove the ability to upload all the events to finalize the game (as a backup)
    olliePipe.emitEvent({
      type: "analytic-upload-all-final-events",
      payload: {
        soccerGameId: currentStartedGameRaw.id
      }
    });

    const missingEvents = _.differenceBy(eventsUpToEndEvent, serverQueriedEvents, "id");
    let missingUndos = _.differenceBy(serverQueriedEvents, eventsUpToEndEvent, "id");

    //The chances that a user had 5 undos that ALSO didn't get synced to the server is very low.
    //More likely it's some error in our application logic somehow.
    if (missingUndos.length > 5) {
      olliePipe.emitEvent({
        type: "error-too-many-out-of-sync-undos",
        payload: {
          soccerGameId: currentStartedGameRaw.id,
          typesOfUndoEvents: missingUndos.map(a => a.type).join("-")
        }
      });
      missingUndos = [];
    }

    await Promise.all(
      missingEvents.map(async evt => {
        outOfSyncTasks.push(await h.SoccerGameEvent.set({ id: evt.id, doc: evt }, { returnBatchTask: true }));
      })
    );

    await Promise.all(
      missingUndos.map(async evt => {
        outOfSyncTasks.push(await h.SoccerGameEvent.delete({ id: evt.id }, { returnBatchTask: true }));
      })
    );
  } else {
    eventsUpToEndEvent = serverQueriedEvents;

    if (common__hashObject({ obj: eventsUpToEndEvent }) !== p.md5OfEventsUpToEndEvent) {
      await olliePipe.emitEvent(
        {
          type: "error-events-not-correctly-written-on-finalize-causing-hash-mismatch",
          payload: {
            soccerGameId: currentStartedGameRaw.id,
            numEventsOnClientHash: p.numEventsUpToEndEvent,
            numEventsOnServerFetch: serverQueriedEvents.length
          }
        },
        { sendImmediate: true }
      );

      return { result: "hash-mismatch" };
    }
  }

  //Need to do some tricky stuff to account for the fact that the game technically could still only be in a setup state if the client went offline immediately after finishing setup
  let currentStartedGame: PostStartedSoccerGame;
  if (currentStartedGameRaw.gameStage === "setup") {
    const firstEvent = eventsUpToEndEvent[0];
    if (!firstEvent) {
      await olliePipe.emitEvent(
        {
          type: "error-game-finalize-no-permission",
          payload: {
            recorder: currentStartedGameRaw.recorderAccountId,
            selfAccountId: p.selfAccountId,
            soccerGameId: p.soccerGameId
          }
        },
        { sendImmediate: true }
      );
      throw new Error("Invalid state encountered. When ending a game that is only setup, there must be at least one start event");
    }

    if (currentStartedGameRaw.statMode !== SoccerStatModes.team && !currentStartedGameRaw.roster) {
      await olliePipe.emitEvent({
        type: "error-game-setup-individual-game-without-roster",
        payload: { soccerGameId: p.soccerGameId }
      });
      throw new Error("Should not be able to finalize a setup individual game without a roster");
    }

    currentStartedGame = {
      ...currentStartedGameRaw,
      gameStage: "started",
      roster: currentStartedGameRaw.roster ?? {},
      officialStartOfGameMS: firstEvent.createdAtMS
    };
  } else {
    currentStartedGame = currentStartedGameRaw;
  }

  const { batchTasks, currentCalendarEntry, currentTeam, proposedFinalGame } = await getBatchTasksForEndingAGame({
    soccerGameId: p.soccerGameId,
    currentEventsOverride: eventsUpToEndEvent,
    currentGameOverride: currentStartedGame,
    optionalFinalEventToWrite: p.endGameEvent,
    locale: p.locale
  });

  if (outOfSyncTasks.length) {
    olliePipe.emitEvent({
      type: "analytic-soccer-game-out-of-sync-client-server",
      payload: outOfSyncTasks
    });
    batchTasks.unshift(...outOfSyncTasks);
  }

  const transactionChunks = _.chunk(batchTasks, 400); //500 is the firestore limit
  for (let i = 0; i < transactionChunks.length; i++) {
    const theseBatchTasks = transactionChunks[i];

    const resp = await h._RawFirestore.runTransaction(async transaction => {
      const gameSnap = await transaction.get(h._RawFirestore.collection(SoccerGameCollectionName).doc(p.soccerGameId));
      const game = gameSnap.data() as SoccerGame | undefined;
      if (game?.gameStage !== "ended") {
        h._BatchRunner.executeBatch(theseBatchTasks, { transaction });
        return "success";
      }
      return "double-finalize-recording";
    });

    if (resp === "double-finalize-recording") {
      olliePipe.emitEvent({
        type: "analytic-soccer-game-double-finalize-recording",
        payload: {
          soccerGameId: currentStartedGame.id,
          calendarEntryId: currentStartedGame.calendarEntryId,
          userId: p.selfAccountId
        }
      });
      return { result: "success" };
    }
  }

  const promises: any[] = [];

  if (!currentTeam.hasOnboarded) {
    promises.push(team__server__checkForOnboardingComplete({ teamId: proposedFinalGame.teamId }));
  }

  const { pkScore, score } = proposedFinalGame.officiallyEndedGameSummaryStats;

  const isGameMoreThan4HoursPastScheduledStartTime = timeHasPassedSinceScheduledGameStart(currentCalendarEntry, 4);
  const isGameMoreThan6HoursPastScheduledStartTime = timeHasPassedSinceScheduledGameStart(currentCalendarEntry, 6);

  promises.push(
    notification__server__triggerForNewStats({
      calendarEntryId: proposedFinalGame.calendarEntryId,
      selfAccountId: p.selfAccountId,
      isGameMoreThan4HoursPastScheduledStartTime,
      score,
      pkScore,
      game: currentStartedGame
    })
  );

  if (!isGameMoreThan6HoursPastScheduledStartTime) {
    if (!currentStartedGame.areMVPsDisbaled && currentStartedGame.votingMode !== MVPVotingMode.none) {
      // Schedule a posthook to end voting in 2 hours
      promises.push(
        schedulePosthook({
          webhookData: {
            data: { soccerGameId: p.soccerGameId }
          },
          bifrostFn: soccerGame__server__endVoting,
          minutes: 120,
          apiKey: serverConfig.posthook.apiKey
        })
      );
      // Schedule a posthook to remind people to vote 15 minutes before voting closes
      promises.push(
        schedulePosthook({
          webhookData: {
            data: { soccerGameId: p.soccerGameId }
          },
          bifrostFn: soccerGame__server__sendVotingReminder,
          minutes: 105,
          apiKey: serverConfig.posthook.apiKey
        })
      );
    }
    // If MVPs are enabled and the voting mode is NONE, end the voting
    if (
      currentStartedGame.statMode !== SoccerStatModes.team &&
      !currentStartedGame.areMVPsDisbaled &&
      currentStartedGame.votingMode === MVPVotingMode.none
    ) {
      await soccerGame__server__endVoting({
        data: {
          soccerGameId: p.soccerGameId,
          disableNotification: true
        }
      });
    }

    promises.push(
      schedulePosthook({
        webhookData: {
          data: {
            calendarEntryId: proposedFinalGame.calendarEntryId,
            soccerGameId: proposedFinalGame.id,
            teamId: proposedFinalGame.teamId
          }
        },
        bifrostFn: soccerGame__server__maybeSendCoachShareReminder,
        minutes: 2,
        apiKey: serverConfig.posthook.apiKey
      })
    );
  } else {
    await soccerGame__server__endVoting({
      data: {
        soccerGameId: p.soccerGameId,
        disableNotification: true
      }
    });
  }

  promises.push(
    await h.Team.update({
      id: proposedFinalGame.teamId,
      doc: {
        //Set the timestamp 30 seconds in the future to give our postgres snapshot mirror function time to run and propagate. Used with forceRefetchAtTimestamp
        lastTimeMajorGameUpdateWasMadeMS: Date.now() + 1000 * 30
      }
    })
  );

  promises.push(
    emitMarketingEvent({
      olliePipe: olliePipe,
      event: {
        type: "TeamGameRecorded",
        teamId: proposedFinalGame.teamId
      }
    }).catch((e: any) => {
      console.error(e);
    })
  );

  await Promise.all(promises);

  return { result: "success" };
  // SERVER_ONLY_TOGGLE
}

soccerGame__server__finalizeStatsRecording.auth = async (req: any) => {
  await validateSelfAccountId(req, req.body.selfAccountId);
};

// i18n certified - complete
