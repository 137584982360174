import { EndedSoccerGame, SoccerGameEvent, sandboxSoccerGameId, SoccerStatSnapshot } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { common__client__getGenerator, common__compressString, common__decompressString } from "../common.api";
import { soccerLogicVersion } from "../../soccer-logic-version.json";
import { SoccerStatSnapshotBundle } from "../../soccer-logic";
import { getStatBundleAndMaybeRefresh } from "./helpers/getStatBundleAndMaybeRefresh";

type ReturnObj = {
  statBundle: SoccerStatSnapshotBundle;
  game: EndedSoccerGame;
  events: SoccerGameEvent[];
};

export async function soccerGame__server__getExampleGameData(): Promise<ReturnObj> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const [snapshots, eventBundle, game] = await Promise.all([
    h.SoccerStatSnapshot.query({
      where: [{ soccerGameId: ["==", sandboxSoccerGameId] }, { soccerLogicVersion: ["==", soccerLogicVersion] }]
    }),
    h.SoccerGameEventBundle.getDoc(common__client__getGenerator().SoccerGameEventBundleId(sandboxSoccerGameId)),
    h.SoccerGame.getDoc(sandboxSoccerGameId)
  ]);

  if (!game || !eventBundle || game.gameStage !== "ended" || !snapshots) {
    throw new Error(
      "Unable to fetch example info. Ensure the data has been set from the special button in dev/sandbox stats input."
    );
  }

  const events = JSON.parse(common__decompressString({ str: eventBundle.compressedJSON }));

  const statBundle = await getStatBundleAndMaybeRefresh({ events, game, snapshots: snapshots.docs });

  return {
    statBundle,
    game,
    events
  };
  // SERVER_ONLY_TOGGLE
}

soccerGame__server__getExampleGameData.auth = async (r: any) => {};

// i18n certified - complete
