import { SoccerGame, CalendarEntryGameScrimmage, CalendarEntryId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function soccerGame__client__fetchByCalendarEntryId(p: {
  calendarEntryId: CalendarEntryId;
}): Promise<SoccerGame | undefined> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const res = await h.SoccerGame.query({
    where: [{ calendarEntryId: ["==", p.calendarEntryId] }]
  });

  const game = res.docs.slice().pop();

  return game;
}

// i18n certified - complete
