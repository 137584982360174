import { SoccerGame, SoccerGameId, Votes, AccountId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";

export async function soccerGame__server__updateAccountIdsInLiveGame(p: {
  soccerGameId: SoccerGameId;
  accountId: AccountId;
  status: boolean;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  await h.SoccerGame.update({
    id: p.soccerGameId,
    doc: {
      accountIdsInLiveGameMode: {
        [p.accountId]: p.status
      }
    }
  });
  // SERVER_ONLY_TOGGLE
}

soccerGame__server__updateAccountIdsInLiveGame.auth = () => {};

// i18n certified - complete
