import _ from "lodash";
import { CalendarEntry, PrettyPlayer, SoccerGameEvent, SoccerGame, Team } from "@ollie-sports/models";
import { player__client__fetchPlayers, player__universal__fetchPlayersOnATeam } from "../player.api";
import { soccerGameEventBundle__client__getEvents } from "../soccerGameEventBundle.api";
import { soccerGame__client__queryEvents } from "./soccerGame__queryEvents";
import { getUniversalHelpers } from "../../helpers";

type GameData = { game: SoccerGame; events: SoccerGameEvent[]; prettyPlayers: PrettyPlayer[]; team: Team };

export async function soccerGame__client__getGameData(p: { calendarEntry: CalendarEntry }): Promise<GameData> {
  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();
  const res = await h.SoccerGame.query({
    where: [{ calendarEntryId: ["==", p.calendarEntry.id] }, { teamId: ["==", p.calendarEntry.teamId] }]
  });

  const game = res.docs.slice().pop();

  if (!game) {
    throw new Error("No game found for calendarEntry");
  }

  if (res.docs.length > 1) {
    olliePipe.emitEvent({
      type: "error-more-than-one-soccer-game-per-calendarEntry",
      payload: {
        message:
          "Found more than one soccer game with the same calendarEntryId. There is an error in some application code somewhere.",
        calendarEntryId: p.calendarEntry.id
      }
    });
  }

  let playersProm: Promise<PrettyPlayer[]>;
  if (game.gameStage === "started" || game.gameStage === "ended") {
    playersProm = player__client__fetchPlayers({ playerIds: Object.keys(game.roster) });
  } else {
    playersProm = player__universal__fetchPlayersOnATeam({
      teamId: p.calendarEntry.teamId,
      filterToSquad: p.calendarEntry.squad ? p.calendarEntry.squad : undefined
    });
  }

  const eventsProm: Promise<SoccerGameEvent[]> = new Promise(async resolve => {
    if (game.gameStage === "started") {
      resolve(await soccerGame__client__queryEvents({ soccerGameId: game.id }));
    } else if (game.gameStage === "ended") {
      const bundledEvents = await soccerGameEventBundle__client__getEvents({ soccerGameId: game.id });

      if (!bundledEvents) {
        resolve([]);
      } else {
        resolve(_.orderBy(bundledEvents, "createdAtMS"));
      }
    } else {
      resolve([]);
    }
  });

  const [team, prettyPlayers, events] = await Promise.all([h.Team.getDoc(p.calendarEntry.teamId), playersProm, eventsProm]);

  if (!team) {
    throw new Error("No team");
  }

  return {
    game,
    events: events || [],
    prettyPlayers: prettyPlayers || [],
    team
  };
}

// i18n certified - complete
