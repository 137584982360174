import { BatchTask } from "@ollie-sports/firebase";
import {
  CalendarEntry,
  CalendarEntryGameScrimmage,
  CALENDAR_ENTRY_TYPES,
  EndedSoccerGame,
  isPostStartedSoccerGame,
  PostStartedSoccerGame,
  SGE_stopHalf,
  SoccerGameEvent,
  SoccerGameEventType,
  StartedSoccerGame,
  Team
} from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../../helpers";
import {
  computeSoccerGameAwards,
  computeSoccerGameEndGameDetails,
  isGoalEvent,
  SoccerStatSnapshotBundle
} from "../../../soccer-logic";
import { soccerGameEventBundle__client__refresh } from "../../soccerGameEventBundle.api";
import { soccerGame__client__queryEvents } from "../soccerGame__queryEvents";
import { refreshStatSnapshots } from "./refreshStatSnapshots";

export async function getBatchTasksForEndingAGame(p: {
  soccerGameId: string;
  //Callers can supply an override if they know what these object would be if they were queried right now
  currentGameOverride?: PostStartedSoccerGame;
  currentEventsOverride?: SoccerGameEvent[];
  optionalFinalEventToWrite?: SGE_stopHalf;
  locale: string;
}): Promise<{
  batchTasks: BatchTask[];
  currentTeam: Team;
  currentCalendarEntry: CalendarEntryGameScrimmage;
  proposedFinalGame: EndedSoccerGame;
  proposedStatBundle: SoccerStatSnapshotBundle;
  proposedFinalEvents: SoccerGameEvent[];
}> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const currentGame = p.currentGameOverride || (await h.SoccerGame.getDoc(p.soccerGameId));

  const serverGameProm = h.SoccerGame.getDoc(p.soccerGameId);

  if (!currentGame || !isPostStartedSoccerGame(currentGame)) {
    throw new Error(p.currentGameOverride ? "Invalid gameOverride passed" : "Unable to find a valid current game for id");
  }

  if (currentGame.gameStage === "ended" && p.optionalFinalEventToWrite) {
    throw new Error("May not pass optionalFinalEventToWrite for game that has already ended");
  }

  const eventsPromise = p.currentEventsOverride
    ? Promise.resolve(p.currentEventsOverride)
    : soccerGame__client__queryEvents({ soccerGameId: p.soccerGameId });

  const dataProm = Promise.all([h.Team.getDoc(currentGame.teamId), h.CalendarEntry.getDoc(currentGame.calendarEntryId)]);

  const finalEvents = await eventsPromise;
  if (p.optionalFinalEventToWrite) {
    if (finalEvents.slice(-1).pop()?.type === SoccerGameEventType.stopHalf) {
      throw new Error("Passed optionalFinalEventToWrite, but the final event on the events array is already a stop half event.");
    }
    finalEvents.push(p.optionalFinalEventToWrite);
  }

  const finalEvent = finalEvents[finalEvents.length - 1];

  if (!finalEvent || finalEvent.type !== SoccerGameEventType.stopHalf || !finalEvent.endsGame) {
    throw new Error("Final event is not of correct type to end game");
  }

  let finalGameChanges: Pick<
    EndedSoccerGame,
    "gameStage" | "officialEndOfGameMS" | "officiallyEndedGameSummaryStats" | "leaderboardWinners"
  > = {
    gameStage: "ended" as const,
    officialEndOfGameMS: finalEvent.createdAtMS,
    officiallyEndedGameSummaryStats: computeSoccerGameEndGameDetails({
      goalEvents: finalEvents.filter(isGoalEvent),
      endGameEvent: finalEvent as any,
      statMode: currentGame.statMode
    })
  };

  let finalGame: EndedSoccerGame = {
    ...currentGame,
    ...finalGameChanges
  };

  //Compute blocking stats computation only after the queries have been kicked off and the server is sitting around waiting
  await new Promise(res => setTimeout(res, 10));

  const { batchTasks: statsSnapshotTasks, ...statBundle } = await refreshStatSnapshots({
    events: finalEvents,
    game: finalGame,
    returnBatchTask: true
  });

  const { all: soccerStatSnapshot } = statBundle;

  if (finalGame.statMode !== "team" && !finalGame.areAwardsDisabled) {
    const awards = computeSoccerGameAwards({ game: finalGame, snapshot: soccerStatSnapshot, locale: p.locale });

    const leaderboardWinners = _(awards.leaderboards)
      .mapValues(leaderboard => leaderboard?.[0].playerId)
      .pickBy(a => !!a)
      .valueOf();

    finalGameChanges = {
      ...finalGameChanges,
      leaderboardWinners
    };

    finalGame = {
      ...finalGame,
      leaderboardWinners
    };
  }

  if (finalGame.gameStage !== "ended") {
    throw new Error(`Invalid finalGame passed to finalizeStatsRecording`);
  }

  const [team, calendarEntry] = await dataProm;

  if (!team) {
    throw new Error(`No team found for ${finalGame.teamId}`);
  }

  if (
    !calendarEntry ||
    (calendarEntry.calendarEntryType !== CALENDAR_ENTRY_TYPES.game &&
      calendarEntry.calendarEntryType !== CALENDAR_ENTRY_TYPES.scrimmage)
  ) {
    throw new Error(`No valid calendarEntry found for ${finalGame.calendarEntryId}`);
  }

  if (p.currentGameOverride) {
    const serverGame = await serverGameProm;

    if (!serverGame) {
      throw new Error("Unable to provide currentGameOverride if game does not actually exist!");
    }

    if (serverGame.id !== p.currentGameOverride.id) {
      throw new Error("currentGameOverride game id does not match the game id for the fetched game");
    }
  }

  const batchTasks: BatchTask[] = [
    // Add game ending event
    await h.SoccerGameEvent.set({ id: finalEvent.id, doc: finalEvent }, { returnBatchTask: true }),

    // Set soccer game
    await h.SoccerGame.update({ id: finalGame.id, doc: finalGameChanges }, { returnBatchTask: true }),

    // Add the soccer game event bundle
    await soccerGameEventBundle__client__refresh({
      calendarEntryId: calendarEntry.id,
      finalEvents: finalEvents,
      soccerGameId: finalGame.id,
      teamId: finalGame.teamId,
      returnBatchTask: true
    }),

    // Set the end game details on the calendar entry
    await h.CalendarEntry.setPath(
      {
        id: finalGame.calendarEntryId,
        value: {
          derived: {
            endGameDetails: finalGame.officiallyEndedGameSummaryStats
          }
        },
        pathObj: { derived: { endGameDetails: true } }
      },
      { returnBatchTask: true }
    ),

    // Add game ending event
    p.optionalFinalEventToWrite
      ? await h.SoccerGameEvent.set({ id: finalEvent.id, doc: finalEvent }, { returnBatchTask: true })
      : null,

    // Write stats snapshots
    ...statsSnapshotTasks
  ].filter((a): a is BatchTask => !!a);

  return {
    batchTasks,
    currentTeam: team,
    currentCalendarEntry: calendarEntry,
    proposedFinalGame: finalGame,
    proposedFinalEvents: finalEvents,
    proposedStatBundle: statBundle
  };
}

// i18n certified - complete
