import { SoccerGameId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { Request } from "express";
import { fetchAccountIdsOnSquad } from "../../internal-utils/team-utils";
import { notification__server__triggerForVotingReminder } from "../notification/notification__triggerForVotingReminder";
import { validateToken } from "../../internal-utils/server-auth";
import { ServerThisContext } from "@ollie-sports/react-bifrost";

export async function soccerGame__server__sendVotingReminder(
  this: ServerThisContext,
  p: {
    data: {
      soccerGameId: SoccerGameId;
    };
  }
) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();

  const soccerGame = await h.SoccerGame.getDoc(p.data.soccerGameId);

  // If we can't find the soccer game, the game hasn't ended, or there are already winners computed, just do nothing
  if (!soccerGame || soccerGame.gameStage !== "ended" || !!soccerGame.mvpWinners) {
    return;
  }

  const calendarEntry = await h.CalendarEntry.getDoc(soccerGame.calendarEntryId);
  const team = await h.Team.getDoc(soccerGame.teamId);

  if (!team || !calendarEntry) {
    return;
  }

  let accountIds = Object.keys(team.accounts);
  if (calendarEntry.squad) {
    accountIds = await fetchAccountIdsOnSquad({ team: team, squad: calendarEntry.squad, squadSubset: "all" });
  }

  const accountIdsToRemind = accountIds.filter(aid => !soccerGame.votes?.[aid]);

  olliePipe.emitEvent({ type: "analytic-voting-reminder-sent", payload: { soccerGameId: p.data.soccerGameId } });

  // Send out voting reminder notification
  await notification__server__triggerForVotingReminder({
    accountIds: accountIdsToRemind,
    calendarEntryId: calendarEntry.id,
    soccerGame: soccerGame
  });
  // SERVER_ONLY_TOGGLE
}

soccerGame__server__sendVotingReminder.auth = async (req: Request) => {
  if (req.get("x-ph-signature")) {
    //TODO: Verify posthook signature...
  } else {
    //TODO: Probably throw... Should only be called by Posthook
  }
};

// i18n certified - complete
