import { CalendarEntryId, SoccerGame, SoccerGameId } from "@ollie-sports/models";
import {
  firestoreLiftDocSubToBifrostSub,
  firestoreLiftQuerySubToBifrostSub
} from "../../internal-utils/firestoreLiftSubToBifrostSub";
import { BifrostSubscription } from "@ollie-sports/react-bifrost";
import { getUniversalHelpers } from "../../helpers";

export function soccerGame__client__byIdSubscription(p: { soccerGameId: SoccerGameId }): BifrostSubscription<SoccerGame | null> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return firestoreLiftDocSubToBifrostSub(h.SoccerGame.docSubscription(p.soccerGameId));
}

// i18n certified - complete
