import { getUniversalHelpers } from "../../helpers";
import { RTBDRefs } from "../../constants";

export async function soccerGame__client__takeControl(p: { soccerGameId: string; recorderAccountId: string }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.SoccerGame.update({ id: p.soccerGameId, doc: { recorderAccountId: p.recorderAccountId } });

  await RTBDRefs.getSoccerGameInfoRef({ soccerGameId: p.soccerGameId }).update({
    recorderHeartbeatMS: Date.now()
  });
}

// i18n certified - complete
