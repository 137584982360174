import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import {
  PersistChangesParams,
  persistChangesToGame,
  assertAccountIdHasPermissionToPersistChanges
} from "./helpers/persistChangesToGame";

export const soccerGame__server__persistChangesAndMaybeRefresh = (p: PersistChangesParams) => {
  return persistChangesToGame({ ...p, shouldTriggerRefreshOfConnectedData: true });
};

soccerGame__server__persistChangesAndMaybeRefresh.auth = async (req: any) => {
  await Promise.all([
    validateTokenAndEnsureSelfAccountIdMatches(req as any)
    // TODO: Add security. Need to send along the team id
    // assertAccountIdHasPermissionToPersistChanges({
    //   accountId: req.body.selfAccountId,
    //   teamId: req.body.teamId
    // })
  ]);
};
// i18n certified - complete
