import { SoccerGame, SoccerGameEvent, SoccerGameCollectionName, TEAM_PERMISSIONS } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../../helpers";
import { BatchTask } from "@ollie-sports/firebase";
import _ from "lodash";
import { getBatchTasksForEndingAGame } from "./getBatchTasksForEndingAGame";
import { hasPermissionOnTeam } from "../../../compute";

export type PersistChangesParams = {
  soccerGameId: string;
  selfAccountId: string;
  gameChanges?: Partial<SoccerGame>;
  editedEvents?: SoccerGameEvent[];
  addedEvents?: SoccerGameEvent[];
  deletedEvents?: SoccerGameEvent[];
  locale: string;
};

export async function persistChangesToGame(
  p: PersistChangesParams & {
    shouldTriggerRefreshOfConnectedData?: boolean;
  }
) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  if (p.addedEvents?.length && (await h.SoccerGameEvent.getDoc(p.addedEvents[0]!.id))) {
    //TODO: Need a better way to make this function idempotent. We guard against it reasonably well on the client so it appears to not be a practical problem,
    //but theoretically gameChanges can arrive out of order and make the game become incorrect.
    return;
  }

  (p.editedEvents || []).concat(p.addedEvents || [], p.deletedEvents || []).forEach(evt => {
    if (evt.soccerGameId !== p.soccerGameId) {
      throw new Error("soccerGameId does not match the game id on the proposed event changes");
    }
  });

  const gameProm = p.shouldTriggerRefreshOfConnectedData ? h.SoccerGame.getDoc(p.soccerGameId) : null;

  if (p.gameChanges) {
    p.gameChanges = _.omit(p.gameChanges, ["id", "calendarEntryId", "createdAtMS"]);
  }

  const batchTasks: BatchTask[] = [];

  //Update game
  let gameChangesProm: Promise<any> = Promise.resolve();
  if (p.gameChanges) {
    if (p.shouldTriggerRefreshOfConnectedData) {
      gameChangesProm = h.SoccerGame.update({ doc: p.gameChanges, id: p.soccerGameId });
    } else {
      gameChangesProm = h._RawFirestore.runTransaction(transaction => {
        return transaction.get(h._RawFirestore.collection(SoccerGameCollectionName).doc(p.soccerGameId)).then(soccerGameSnap => {
          const soccerGame = soccerGameSnap.data() as SoccerGame | undefined;
          if (soccerGame?.gameStage !== "ended") {
            soccerGameSnap.ref.update(p.gameChanges as any);
          } else {
            //Ignore. Was likely a misfire. Technically we should make the parameters to this function more clear, since this is likely unexpected behavior
          }
        });
      });
    }
  }

  //TODO: We shouldn't actually delete game events when they're undone. We should just append a new delete game event.
  //Doing it this way exposes us to race conditions if the "undo" event gets saved before the event is actually written.

  //Delete events
  if (p.deletedEvents) {
    batchTasks.push(
      ...(await Promise.all(
        p.deletedEvents.map(async item => await h.SoccerGameEvent.delete({ id: item.id }, { returnBatchTask: true }))
      ))
    );
  }

  //Add events
  if (p.addedEvents) {
    batchTasks.push(
      ...(await Promise.all(
        p.addedEvents.map(async doc => await h.SoccerGameEvent.set({ id: doc.id, doc }, { returnBatchTask: true }))
      ))
    );
  }

  //Edit events
  if (p.editedEvents) {
    batchTasks.push(
      ...(await Promise.all(
        p.editedEvents.map(async doc => await h.SoccerGameEvent.set({ id: doc.id, doc }, { returnBatchTask: true }))
      ))
    );
  }

  await Promise.all([h._BatchRunner.executeBatch(batchTasks), gameChangesProm]);

  if (p.shouldTriggerRefreshOfConnectedData) {
    const game = await gameProm;
    if (game?.gameStage === "ended") {
      const { batchTasks: refreshGameBatchTasks } = await getBatchTasksForEndingAGame({
        soccerGameId: p.soccerGameId,
        locale: p.locale
      });
      await h._BatchRunner.executeBatch(refreshGameBatchTasks);
    }
  }
}

export async function assertAccountIdHasPermissionToPersistChanges(p: { accountId: string; teamId: string }) {
  const team = await getUniversalHelpers().ollieFirestoreV2.Team.getDoc(p.teamId);
  if (!team) {
    throw new Error("Unable to find team. Unauthorized.");
  }

  const result = hasPermissionOnTeam({ team, accountId: p.accountId, permission: TEAM_PERMISSIONS.recordStats });
  if (!result) {
    throw new Error("Unauthorized access");
  }
}

// i18n certified - complete
