import { SoccerGame, CalendarEntryGameScrimmage } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function soccerGame__client__fetchByCalendarEntry(p: {
  calendarEntry: CalendarEntryGameScrimmage;
}): Promise<SoccerGame | undefined> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const res = await h.SoccerGame.query({
    where: [{ calendarEntryId: ["==", p.calendarEntry.id] }, { teamId: ["==", p.calendarEntry.teamId] }]
  });

  const game = res.docs.slice().pop();

  return game;
}
// i18n certified - complete
