import { getUniversalHelpers, getServerHelpers } from "../../helpers";
import { CalendarEntry } from "@ollie-sports/models";
import { soccerGame__server__getStatsViewingGameData } from "./soccerGame__getStatsViewingGameData";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";

const ANALYTICS_KEY = "analytic-query-rate-limited-game-data";

type ThenArg<T> = T extends PromiseLike<infer U> ? U : T extends (...args: any[]) => PromiseLike<infer V> ? V : T;

type SuccessRet = {
  success: true;
  gameData: ThenArg<ReturnType<typeof soccerGame__server__getStatsViewingGameData>> & { calendarEntry: CalendarEntry };
};

export async function soccerGame__server__getGameData(p: {
  calendarEntryId: string;
  selfAccountId?: string;
  locale: string;
}): Promise<SuccessRet | { success: false; error: "limit-exceeded" } | { success: false; error: "no-license" }> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const canViewStats: boolean = await canSeeGameStats(p);

  if (canViewStats) {
    const calendarEntry = await h.CalendarEntry.getDoc(p.calendarEntryId);
    if (!calendarEntry) {
      throw new Error("Invalid calendarEntryId");
    }

    const data = await soccerGame__server__getStatsViewingGameData({
      calendarEntryId: p.calendarEntryId,
      selfAccountId: "super-admin-magic-string",
      locale: p.locale
    });
    return {
      success: true,
      gameData: {
        ...data,
        calendarEntry
      }
    };
  } else {
    if (p.selfAccountId) {
      return {
        success: false as const,
        error: "no-license"
      };
    } else {
      return {
        success: false as const,
        error: "limit-exceeded"
      };
    }
  }
  // SERVER_ONLY_TOGGLE
}

soccerGame__server__getGameData.auth = async (req: any) => {
  if (req.body.selfAccountId) {
    await validateTokenAndEnsureSelfAccountIdMatches(req);
  }
};

async function canSeeGameStats(p: { calendarEntryId: string; selfAccountId?: string }): Promise<boolean> {
  // SERVER_ONLY_TOGGLE

  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();

  // Check account license
  if (p.selfAccountId) {
    const accountPrivate = await h.AccountPrivate.getDoc(p.selfAccountId);
    if (accountPrivate?.linkedLicenseId) {
      return true;
    }
  }

  // Check for team/club license
  const ca = await h.CalendarEntry.getDoc(p.calendarEntryId);
  if (!ca) {
    throw new Error("Unable to find calendarEntry " + p.calendarEntryId);
  }
  const team = await h.Team.getDoc(ca.teamId);
  if (!team) {
    throw new Error("Unable to find team " + ca.teamId);
  }
  if (team.linkedLicenseId) {
    return true;
  }

  // If they are not logged in we assume this is the coach share. See if we have hit the share limit
  if (!p.selfAccountId) {
    const { getAppPgPool } = getServerHelpers();
    const prevViews = await getAppPgPool().query(
      `SELECT count(*) FROM general_analytic WHERE event_type = '${ANALYTICS_KEY}' AND payload->>'calendarEntryId' = '${p.calendarEntryId}'`
    );

    const RATE_LIMIT = 3;
    if (prevViews.rows && prevViews.rows[0] && prevViews.rows[0].count < RATE_LIMIT) {
      await olliePipe.emitEvent(
        {
          type: ANALYTICS_KEY,
          payload: {
            calendarEntryId: p.calendarEntryId
          }
        },
        { sendImmediate: true }
      );
      return true;
    }
  }

  return false;
  // SERVER_ONLY_TOGGLE
}

// i18n certified - complete
