import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";
import { AccountId, CalendarEntryId, SoccerGameId, TeamId, Team__StaffTypes, USER_INTERACTIONS } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { ObjectKeys } from "../../utils";
import { accountPrivate__client__setUserInteraction } from "../account/accountPrivate__setUserInteraction";
import { notification__server__triggerForCoachShareReminder } from "../notification/notification__triggerForCoachShareReminder";

export async function soccerGame__server__maybeSendCoachShareReminder(p: {
  data: {
    teamId: TeamId;
    calendarEntryId: CalendarEntryId;
    soccerGameId: SoccerGameId;
  };
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();

  const { calendarEntryId, soccerGameId, teamId } = p.data;

  const team = await h.Team.getDoc(teamId);

  if (!team) {
    return;
  }

  const headCoachIds = ObjectKeys(team.accounts).filter(aid => team.accounts[aid]?.staffTitle === Team__StaffTypes.headCoach);

  if (!headCoachIds?.length) {
    return;
  }

  await Promise.all(
    headCoachIds.map(async aid => {
      const accountPrivate = await h.AccountPrivate.getDoc(aid);

      if (
        !accountPrivate ||
        (accountPrivate.userInteractions?.numberOfGamesAsHeadCoachSinceWeAddedCoachShareReminder &&
          accountPrivate.userInteractions?.numberOfGamesAsHeadCoachSinceWeAddedCoachShareReminder >= 8) // Once they have 8, stop counting
      ) {
        return;
      }

      const numberOfGamesAsHeadCoach =
        accountPrivate.userInteractions?.numberOfGamesAsHeadCoachSinceWeAddedCoachShareReminder ?? 0;

      // Send them notifications on their 3rd, 6th, and 8th games
      if (numberOfGamesAsHeadCoach === 2 || numberOfGamesAsHeadCoach === 5 || numberOfGamesAsHeadCoach === 7) {
        await notification__server__triggerForCoachShareReminder({
          accountId: aid,
          calendarEntryId: calendarEntryId,
          soccerGameId: soccerGameId
        });
        olliePipe.emitEvent({ type: "analytic-coach-share-reminder-sent", payload: { accountId: aid } });
      }

      // Update the count
      await accountPrivate__client__setUserInteraction({
        accountId: aid,
        userInteraction: USER_INTERACTIONS.numberOfGamesAsHeadCoachSinceWeAddedCoachShareReminder,
        value: numberOfGamesAsHeadCoach + 1
      });
    })
  );
  // SERVER_ONLY_TOGGLE
}

soccerGame__server__maybeSendCoachShareReminder.auth = async (req: express.Request) => {
  if (req.get("x-ph-signature")) {
    //TODO: Verify posthook signature...
  } else {
    //TODO: Probably throw here... Should only be called by posthook
  }
};

// i18n certified - complete
