import { SoccerGame, CalendarEntryId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { BifrostSubscription, createBifrostSubscription } from "@ollie-sports/react-bifrost";

export function soccerGame__client__getSubscriptionFromCalendarEntryId(p: {
  calendarEntryId: CalendarEntryId;
}): BifrostSubscription<SoccerGame | null> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const disposeFns: Function[] = [];

  let instance = createBifrostSubscription<SoccerGame | null>({
    dispose: () => disposeFns.forEach(fn => fn())
  });

  const sub = h.SoccerGame.querySubscription({
    where: [{ calendarEntryId: ["==", p.calendarEntryId] }]
  }).subscribe(
    async r1 => {
      instance.nextData(r1.docs[0] ?? null);
    },
    e => {
      console.error(e.message);
    }
  );

  disposeFns.push(sub.unsubscribe);

  return instance;
}
// i18n certified - complete
