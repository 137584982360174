import { SoccerGame, Team, CalendarEntryGameScrimmage } from "@ollie-sports/models";

import { getUniversalHelpers } from "../../helpers";
import { fetchDefaultGameForTeam } from "./helpers/fetchDefaultGameForTeam";

export async function soccerGame__client__getDefaultGameForSandbox(p: {
  teamId: string;
  calendarEntryId: string;
  squad?: "a" | "b" | "c";
}): Promise<SoccerGame> {
  const newGame = await fetchDefaultGameForTeam({
    calendarEntryId: p.calendarEntryId,
    teamId: p.teamId,
    squad: p.squad,
    recorderAccountId: ""
  });

  return newGame;
}

// i18n certified - complete
