import { CalendarEntryId, SoccerGame } from "@ollie-sports/models";
import { firestoreLiftQuerySubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";
import { BifrostSubscription } from "@ollie-sports/react-bifrost";
import { getUniversalHelpers } from "../../helpers";

export async function soccerGame__client__byCalendarId(p: { calendarEntryId: CalendarEntryId }): Promise<SoccerGame | null> {
  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();

  const games = await h.SoccerGame.query({ where: [{ calendarEntryId: ["==", p.calendarEntryId] }] });

  if (games.docs.length > 1) {
    olliePipe.emitEvent({
      type: "error-more-than-one-soccer-game-per-calendarEntry",
      payload: { calendarEntryId: p.calendarEntryId }
    });
  }

  if (!games.docs.length) {
    return null;
  } else {
    return games.docs[0];
  }
}
// i18n certified - complete
