import { CalendarEntryId, SoccerGame } from "@ollie-sports/models";
import { firestoreLiftQuerySubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";
import { BifrostSubscription } from "@ollie-sports/react-bifrost";
import { getUniversalHelpers } from "../../helpers";

export function soccerGame__client__byCalendarIdSubscription(p: {
  calendarEntryId: CalendarEntryId;
}): BifrostSubscription<SoccerGame[]> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return firestoreLiftQuerySubToBifrostSub(
    h.SoccerGame.querySubscription({ where: [{ calendarEntryId: ["==", p.calendarEntryId] }] })
  );
}
// i18n certified - complete
