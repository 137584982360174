import { EndedSoccerGame, SoccerGameEvent, sandboxSoccerGameId, SANDBOX_SOCCER_GAME } from "@ollie-sports/models";
import md5 from "md5";
import stableStringify from "json-stable-stringify";
import { validateToken } from "../../internal-utils/server-auth";
import { getUniversalHelpers } from "../../helpers";
import { common__client__getGenerator, common__compressString } from "../common.api";
import { GET_SANDBOX_TEAM } from "../../utils";

export async function soccerGame__server__refreshFinishedExampleData(p: {
  soccerGame: EndedSoccerGame;
  finalEvents: SoccerGameEvent[];
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const compressedJSON = common__compressString({ str: JSON.stringify(p.finalEvents) });

  await h.SoccerGame.set({
    id: sandboxSoccerGameId,
    doc: p.soccerGame
  });

  const bundleId = common__client__getGenerator().SoccerGameEventBundleId(sandboxSoccerGameId);
  await h.SoccerGameEventBundle.set({
    id: bundleId,
    doc: {
      id: bundleId,
      calendarEntryId: SANDBOX_SOCCER_GAME().calendarEntryId,
      createdAtMS: Date.now(),
      teamId: GET_SANDBOX_TEAM({ locale: "en-us" }).id,
      compressedJSON,
      alg: "pako",
      md5OfEvents: md5(stableStringify(p.finalEvents)),
      soccerGameId: SANDBOX_SOCCER_GAME().id
    }
  });
  // SERVER_ONLY_TOGGLE
}

soccerGame__server__refreshFinishedExampleData.auth = async (r: any) => {
  await validateToken(r);
};

// i18n certified - complete
